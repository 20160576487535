<template>
  <div>
    <div style="text-align: center">
      <b>Стоимость порции</b>
      <div class="flex-food-main">
        <div class="flex-food-main">
          <div style="width: 70px; padding-top: 7px">1 смена</div>
          <div class="flex" style="width: 120px; padding-right: 30px">
            <input
              type="number"
              v-model="sumOneShift"
              step="any"
              required
              class="form-control"
            />
          </div>
        </div>
        <div class="flex-food-main">
          <div style="width: 70px; padding-top: 7px">2 смена</div>
          <div class="flex" style="width: 120px">
            <input
              type="number"
              step="any"
              v-model="sumTwoShift"
              required
              class="form-control"
            />
          </div>
        </div>
      </div>
    </div>
    <div style="text-align: center">
      <b>Коэффиценты для рассчета (K)</b>
      <div>
        (Сумма со всех выбранных квитанций / Стоимость порции для смены) * K
      </div>
      <div>Сокращается до целого (*.49- - в меньшую, *.50+ - в большую)</div>
      <div class="flex-food-main">
        <div class="flex-food-main">
          <div style="width: 70px; padding-top: 7px">Местный бюджет</div>
          <div class="flex" style="width: 120px; padding-right: 30px">
            <input
              type="number"
              v-model="localСoef"
              step="any"
              required
              class="form-control"
            />
          </div>
        </div>
        <div class="flex-food-main">
          <div style="width: 70px; padding-top: 7px">Краевой бюджет</div>
          <div class="flex" style="width: 120px">
            <input
              type="number"
              step="any"
              v-model="regionCoef"
              required
              class="form-control"
            />
          </div>
        </div>
      </div>
    </div>
    <div style="text-align: center">
      <b> Загрузить по диапазону</b>
      <div class="flex-food-main">
        <div class="flex-food-main">
          <div style="width: 70px; padding-top: 6px">От</div>
          <div class="flex" style="width: 220px">
            <input
              type="date"
              v-model="dateFrom"
              id="date"
              required
              class="form-control"
            />
          </div>
        </div>
        <div class="flex-food-main">
          <div style="width: 30px; padding-top: 6px">До</div>
          <div class="flex" style="width: 220px">
            <input
              type="date"
              v-model="dateBefore"
              id="date"
              required
              class="form-control"
            />
          </div>
        </div>
        <div class="flex-food-main">
          <div class="flex-food" style="width: 100px; padding: 2px 0 0 5px">
            <button @click="init()" class="btn btn-success">Загрузить</button>
          </div>
        </div>
      </div>
      <br />
    </div>

    <div style="text-align: center">
      <div class="flex-food-main">
        <div class="flex-food" style="">
          <button @click="payCompensation()" class="btn btn-primary">
            Зафиксировать компенсацию по выбранным квитанциям
          </button>
        </div>
      </div>
    </div>
    <br />
    <div
      style="
        margin-left: auto;
        margin-right: auto;
        width: 98%;
        text-align: center;
      "
    >
      <div style="text-align: center; margin-bottom: 5px">
        <div class="flex-food-main">
          <div class="flex-food">
            <button @click="hiddenRecipts()" class="btn btn-secondary">
              Убрать колонку Квитанции
            </button>
          </div>
          <div class="flex-food" style="margin-left: 3px">
            <button @click="pickTable()" class="btn btn-success">
              Скопировать в EXCEL
            </button>
          </div>
          <div class="flex-food" style="margin-left: 3px">
            <button @click="downloadCSV()" class="btn btn-success">
              Скачать CSV файл (местный)
            </button>
          </div>
          <div class="flex-food" style="margin-left: 3px">
            <button @click="downloadCSVRegion()" class="btn btn-success">
              Скачать CSV файл (краевой)
            </button>
          </div>
        </div>
      </div>
      <div>
        Для быстрого поиска по таблице используйте сочетание клавиш Ctrl+F
      </div>
      <div style="display: flex; justify-content: center" v-if="loading === 0">
        <Preloader />
      </div>
      <!-- <v-btn
        style="margin-top: 10px; margin-bottom: 10px"
        color="success"
        block
        @click="testPush()"
        >test</v-btn
      > -->
      <table class="table" id="table" v-if="loading == 100">
        <thead>
          <tr>
            <th rowspan="2">№</th>
            <th rowspan="2">Класс</th>
            <th rowspan="2">Фамилия</th>

            <th rowspan="2">Имя</th>
            <th rowspan="2">Категория</th>
            <th rowspan="2" style="max-width: 90px">Смена</th>
            <th rowspan="2">Порций за период</th>
            <th rowspan="2">
              Сумма по <br />
              кол/ву порций
            </th>
            <th rowspan="2" style="width: 300px" id="columnReciept">
              Квитанции
            </th>
            <th rowspan="2">Сумма квитанций</th>
            <th colspan="2">Компенсация</th>
            <th rowspan="2">Л\с</th>
            <th rowspan="2">Реквизиты банка</th>
            <th rowspan="2">Получатель</th>
          </tr>
          <tr>
            <th style="top: 30px; max-width: 60px">Местный бюджет</th>
            <th style="top: 30px; max-width: 60px">Краевой бюджет</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(student, index) in studentsList" :key="index">
            <td>
              {{ index + 1 }}
            </td>
            <td id="cell">{{ student.class.className }}</td>
            <td id="cell">{{ student.FirstName }}</td>
            <td id="cell">{{ student.LastName }}</td>
            <td id="cell">{{ student.Category }}</td>
            <td style="max-width: 90px" id="cell">{{ student.class.shift }}</td>
            <td id="center">{{ student.amount }}</td>
            <td>
              <div v-if="student.class.shift === '1 смена'">
                {{ student.amount * sumOneShift }}
              </div>
              <div v-if="student.class.shift === '2 смена'">
                {{ student.amount * sumTwoShift }}
              </div>
            </td>
            <td id="columnReciept" style="text-align: left">
              <div
                v-if="student.Order.Sberbank"
                style="
                  font-weight: 900;
                  background-color: darkgreen;
                  color: white;
                  text-align: center;
                "
              >
                СБЕРБАНК
              </div>
              <ul style="display: flex; flex-direction: column">
                <li
                  v-for="(reciept, index) in student.receipts"
                  :key="index"
                  :title="
                    'ID:' + reciept.identifier + '\nКатегория' + reciept.cat
                  "
                  style="display: flex; flex-wrap: nowrap"
                >
                  <input
                    @change="changeReciept(student)"
                    class="form-check-input"
                    type="checkbox"
                    value=""
                    v-model="reciept.selected"
                    :id="reciept.identifier"
                    style="margin-right: 3px; padding: 9px; margin-top: 0px"
                  />
                  <label :for="reciept.identifier"
                    >{{ reciept.amount }}р. от
                    {{ new Date(reciept.date).toLocaleDateString() }} за
                    {{ reciept.period }}</label
                  >
                  <div style="cursor: pointer" @click="deleteReciept(reciept)">
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill="purple"
                        d="M11.71 10.29C11.8037 10.383 11.8781 10.4936 11.9289 10.6154C11.9797 10.7373 12.0058 10.868 12.0058 11C12.0058 11.132 11.9797 11.2627 11.9289 11.3846C11.8781 11.5064 11.8037 11.617 11.71 11.71C11.617 11.8037 11.5064 11.8781 11.3846 11.9289C11.2627 11.9797 11.132 12.0058 11 12.0058C10.868 12.0058 10.7373 11.9797 10.6154 11.9289C10.4936 11.8781 10.383 11.8037 10.29 11.71L9 10.41L7.71 11.71C7.61704 11.8037 7.50644 11.8781 7.38458 11.9289C7.26272 11.9797 7.13201 12.0058 7 12.0058C6.86799 12.0058 6.73728 11.9797 6.61542 11.9289C6.49356 11.8781 6.38296 11.8037 6.29 11.71C6.19627 11.617 6.12188 11.5064 6.07111 11.3846C6.02034 11.2627 5.9942 11.132 5.9942 11C5.9942 10.868 6.02034 10.7373 6.07111 10.6154C6.12188 10.4936 6.19627 10.383 6.29 10.29L7.59 9L6.29 7.71C6.1017 7.5217 5.99591 7.2663 5.99591 7C5.99591 6.7337 6.1017 6.4783 6.29 6.29C6.4783 6.1017 6.7337 5.99591 7 5.99591C7.2663 5.99591 7.5217 6.1017 7.71 6.29L9 7.59L10.29 6.29C10.4783 6.1017 10.7337 5.99591 11 5.99591C11.2663 5.99591 11.5217 6.1017 11.71 6.29C11.8983 6.4783 12.0041 6.7337 12.0041 7C12.0041 7.2663 11.8983 7.5217 11.71 7.71L10.41 9L11.71 10.29Z"
                      />
                    </svg>
                  </div>
                </li>
              </ul>
              <div>
                <svg
                  version="1.1"
                  id="Capa_1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  viewBox="0 0 50 50"
                  xml:space="preserve"
                  width="25px"
                  @click="createReciept(student)"
                  style="cursor: pointer"
                >
                  <circle style="fill: #43b05c" cx="25" cy="25" r="25" />
                  <line
                    style="
                      fill: none;
                      stroke: #ffffff;
                      stroke-width: 2;
                      stroke-linecap: round;
                      stroke-linejoin: round;
                      stroke-miterlimit: 10;
                    "
                    x1="25"
                    y1="13"
                    x2="25"
                    y2="38"
                  />
                  <line
                    style="
                      fill: none;
                      stroke: #ffffff;
                      stroke-width: 2;
                      stroke-linecap: round;
                      stroke-linejoin: round;
                      stroke-miterlimit: 10;
                    "
                    x1="37.5"
                    y1="25"
                    x2="12.5"
                    y2="25"
                  />
                </svg>
                <span @click="createReciept(student)" style="cursor: pointer"
                  >Добавить квитанцию</span
                >
              </div>
            </td>
            <td>
              {{ student.amountRec }}
            </td>
            <td name="local_budget">
              <div
                v-if="student.Category == 'Платник' || student.Category == 'МД'"
              >
                <span v-if="student.class.shift === '1 смена'">
                  {{
                    ((student.amountRec / sumOneShift) * localСoef).toFixed(2)
                  }}
                </span>
                <span v-if="student.class.shift === '2 смена'">
                  {{
                    ((student.amountRec / sumTwoShift) * localСoef).toFixed(2)
                  }}
                </span>
              </div>
            </td>
            <td name="regional_budget">
              <div v-if="student.Category == 'МД'">
                <span v-if="student.class.shift === '1 смена'">
                  {{
                    ((student.amountRec / sumOneShift) * regionCoef).toFixed(2)
                  }}
                </span>
                <span v-if="student.class.shift === '2 смена'">
                  {{
                    ((student.amountRec / sumTwoShift) * regionCoef).toFixed(2)
                  }}
                </span>
              </div>
            </td>
            <td>{{ student.Order.ls }}</td>
            <td>{{ student.Order.bank }}</td>
            <td>
              {{ student.Order.recipientsLastName }}
              {{ student.Order.recipientsFirstName }}
              {{ student.Order.recipientsSurname }}
            </td>
          </tr>
        </tbody>
      </table>
      <div style="height: 200px"></div>
    </div>

    <v-dialog v-model="dialogReciept" width="590px">
      <v-card>
        <v-card-text style="color: black; font-size: 20px">
          Создание квитанции для
          {{ selectStudentReciept.FirstName
          }}{{ selectStudentReciept.LastName }} ({{
            selectStudentReciept.Category
          }})
        </v-card-text>
        <v-card-actions style="display: flex; flex-wrap: wrap">
          <div>
            <div class="flex-food">
              <div>
                <b> Дата оплаты </b> <b style="color: red">*</b>
                <span
                  title="Совет: В дату оплаты ставьте последний день месяца, за который
            происходила оплата квитанцией. В дальнейшем будет правильно
            отображаться отчет, сформированный по диапазону дат (например: родитель оплатил СЕНТЯБРЬ, но сделал это в середине октября. если загрузить отчет с 01.09-31.09, квитанция, оплаченная за сентябрь не будет отображаться, т.к. в это поле была выставлена дата из октября. p.s. дату оплаты вы можете прописать в период оплаты для себя)"
                  ><svg
                    enable-background="new 0 0 128 128"
                    height="24px"
                    id="Layer_1"
                    version="1.1"
                    viewBox="0 0 128 128"
                    width="24px"
                    xml:space="preserve"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                  >
                    <path
                      d="M64.049,114.172v-2l-0.006,2c-27.642,0-50.129-22.486-50.129-50.128c0-27.642,22.487-50.13,50.129-50.13  s50.129,22.488,50.129,50.13C114.172,91.686,91.687,114.172,64.049,114.172z M64.043,17.914c-25.436,0-46.129,20.694-46.129,46.13  c0,25.436,20.693,46.128,46.129,46.128h0.006c25.433,0,46.123-20.692,46.123-46.128C110.172,38.608,89.479,17.914,64.043,17.914z"
                    />
                    <g>
                      <path
                        d="M64.043,74.354c-1.104,0-2-0.896-2-2v-9.756c0-1.104,0.896-2,2-2c5.27,0,9.557-4.286,9.557-9.556   c0-5.271-4.287-9.56-9.557-9.56s-9.557,4.289-9.557,9.56c0,1.104-0.896,2-2,2s-2-0.896-2-2c0-7.477,6.081-13.56,13.557-13.56   S77.6,43.565,77.6,51.042c0,6.796-5.026,12.439-11.557,13.409v7.903C66.043,73.458,65.147,74.354,64.043,74.354z"
                      />
                      <circle cx="64.042" cy="85.098" r="3.796" />
                    </g></svg></span
                ><input
                  type="date"
                  v-model="tempReciept.date"
                  id="date"
                  required
                  class="form-control"
                />
              </div>
              <div>
                <b> Идентификатор (СУИП) </b><b style="color: red">*</b
                ><input class="form-control" v-model="tempReciept.identifier" />
              </div>
            </div>
            <div class="flex-food">
              <div>
                <b
                  >Сумма квитанции<br />
                  (только число, пример: 150.5)
                </b>
                <b style="color: red">*</b>
                <input type="text" class="form-control" v-model="amount" />
              </div>
              <div>
                <b>Период </b> <b style="color: red">*</b>
                <input v-model="tempReciept.period" class="form-control" />
              </div>
            </div>
          </div>
          <v-btn
            v-if="
              amount === 0 ||
              amount === '' ||
              !tempReciept.period ||
              !tempReciept.date ||
              !tempReciept.identifier
            "
            style="margin-top: 10px; margin-bottom: 10px"
            color="success"
            block
            @click="createRecieptServer()"
            disabled
            >Создать квитанцию</v-btn
          >
          <v-btn
            v-else
            style="margin-top: 10px; margin-bottom: 10px"
            color="success"
            block
            @click="createRecieptServer()"
            >Создать квитанцию</v-btn
          >
          <br />
          <v-btn color="primary" block @click="dialogReciept = false"
            >Отмена</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
//import { init } from "events";
import TutorialDataService from "../services/TutorialDataService";
const Preloader = () => import("./Preloader.vue");
export default {
  components: {
    Preloader: Preloader,
  },
  data() {
    return {
      studentsList: [],
      classList: [],
      dateFrom: "",
      dateBefore: "",
      marks: [],
      sumOneShift: 118.2,
      sumTwoShift: 138,
      localСoef: 5,
      regionCoef: 10.4,

      receipts: [],
      loading: false,
      selectStudentReciept: {},
      tempReciept: {},
      dialogReciept: false,
      amount: 0,
    };
  },

  methods: {
    async init() {
      this.studentsList = [];
      this.classList = [];
      this.marks = [];
      this.loading = 0;
      this.receipts = [];

      await this.getStudents();
      await this.getClass();
      await this.getClassName();
      await this.getMarksByDateRange();
      await this.getRecieptsByRangeDatePOST();
      await this.fullLoad();
    },
    pickTable() {
      let target = document.getElementById("table");
      let rng, sel;
      if (document.createRange) {
        rng = document.createRange();
        rng.selectNode(target);
        sel = window.getSelection();
        sel.removeAllRanges();
        sel.addRange(rng);
      } else {
        rng = document.body.createTextRange();
        rng.moveToElementText(target);
        rng.select();
      }

      document.execCommand("copy");
    },
    downloadCSV() {
      let checkSberbank = confirm("Скачать только для Сбербанка?");

      let checkSberResult = true;
      if (checkSberbank) checkSberResult = false;

      function arrayToCsv(data) {
        return data
          .map(
            (row) =>
              row
                .map(String) // convert every value to String
                .map((v) => v.replaceAll('"', '""')) // escape double colons
                .map((v) => `${v}`) // quote it
                .join(";") // comma-separated
          )
          .join("\r\n"); // rows starting on new lines
      }
      function downloadBlob(content, filename, contentType) {
        // Create a blob

        var uint8 = new Uint8Array(content.length);
        for (var i = 0; i < content.length; i++) {
          let x = content.charCodeAt(i);
          if (x >= 1040 && x <= 1103) {
            // Символы А..Я а..я
            x -= 848;
          } else if (x == 1025) {
            // Символ Ё
            x = 168;
          } else if (x == 1105) {
            // Символ ё
            x = 184;
          }
          uint8[i] = x;
        }

        var blob = new Blob([uint8], { type: contentType });
        var url = URL.createObjectURL(blob);
        // Create a link to download it
        var pom = document.createElement("a");
        pom.href = url;
        pom.setAttribute("download", filename);
        pom.click();
      }
      function amountRecieptF(recieptList) {
        let amount = 0;
        for (let i = recieptList.length; i--; ) {
          if (recieptList[i].selected) {
            amount += recieptList[i].amount;
          }
        }
        return amount.toFixed(2);
      }
      // function s2ab(s) {
      //   var buf = new ArrayBuffer(s.length);
      //   var view = new Uint8Array(buf);
      //   for (var i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff;
      //   return buf;
      // }

      let arr = [];
      for (let i = 0; i < this.studentsList.length; i++) {
        if (checkSberResult) {
          if (amountRecieptF(this.studentsList[i].receipts) != 0) {
            let temp = [];
            temp.push(this.studentsList[i].Order.ls);
            temp.push(this.studentsList[i].Order.recipientsLastName);
            temp.push(this.studentsList[i].Order.recipientsFirstName);
            temp.push(this.studentsList[i].Order.recipientsSurname);

            if (this.studentsList[i].class.shift === "1 смена") {
              temp.push(
                (
                  (amountRecieptF(this.studentsList[i].receipts) /
                    this.sumOneShift) *
                  this.localСoef
                ).toFixed(2)
              );
            } else {
              temp.push(
                (
                  (amountRecieptF(this.studentsList[i].receipts) /
                    this.sumTwoShift) *
                  this.localСoef
                ).toFixed(2)
              );
            }

            arr.push(temp);
          }
        } else {
          if (
            amountRecieptF(this.studentsList[i].receipts) != 0 &&
            this.studentsList[i].Order.Sberbank
          ) {
            console.log(this.studentsList[i]);
            let temp = [];
            temp.push(this.studentsList[i].Order.ls);
            temp.push(this.studentsList[i].Order.recipientsLastName);
            temp.push(this.studentsList[i].Order.recipientsFirstName);
            temp.push(this.studentsList[i].Order.recipientsSurname);

            if (this.studentsList[i].class.shift === "1 смена") {
              temp.push(
                (
                  (amountRecieptF(this.studentsList[i].receipts) /
                    this.sumOneShift) *
                  this.localСoef
                ).toFixed(2)
              );
            } else {
              temp.push(
                (
                  (amountRecieptF(this.studentsList[i].receipts) /
                    this.sumTwoShift) *
                  this.localСoef
                ).toFixed(2)
              );
            }

            arr.push(temp);
          }
        }
      }

      let csv = arrayToCsv(arr);

      let day = new Date().getDate();
      let month = new Date().getMonth();
      let year = new Date().getFullYear();
      let fileName = "МБ" + day + month + year;
      downloadBlob(csv, fileName, "text/csv;charset=utf-8,");
    },
    downloadCSVRegion() {
      let checkSberbank = confirm("Скачать только для Сбербанка?");

      let checkSberResult = true;
      if (checkSberbank) checkSberResult = false;
      function arrayToCsv(data) {
        return data
          .map(
            (row) =>
              row
                .map(String) // convert every value to String
                .map((v) => v.replaceAll('"', '""')) // escape double colons
                .map((v) => `${v}`) // quote it
                .join(";") // comma-separated
          )
          .join("\r\n"); // rows starting on new lines
      }
      function downloadBlob(content, filename, contentType) {
        // Create a blob

        var uint8 = new Uint8Array(content.length);
        for (var i = 0; i < content.length; i++) {
          let x = content.charCodeAt(i);
          if (x >= 1040 && x <= 1103) {
            // Символы А..Я а..я
            x -= 848;
          } else if (x == 1025) {
            // Символ Ё
            x = 168;
          } else if (x == 1105) {
            // Символ ё
            x = 184;
          }
          uint8[i] = x;
        }

        var blob = new Blob([uint8], { type: contentType });
        var url = URL.createObjectURL(blob);
        // Create a link to download it
        var pom = document.createElement("a");
        pom.href = url;
        pom.setAttribute("download", filename);
        pom.click();
      }
      function amountRecieptF(recieptList) {
        let amount = 0;
        for (let i = recieptList.length; i--; ) {
          if (recieptList[i].selected) {
            amount += recieptList[i].amount;
          }
        }
        return amount.toFixed(2);
      }
      // function s2ab(s) {
      //   var buf = new ArrayBuffer(s.length);
      //   var view = new Uint8Array(buf);
      //   for (var i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff;
      //   return buf;
      // }

      let arr = [];
      for (let i = 0; i < this.studentsList.length; i++) {
        if (checkSberResult) {
          if (
            amountRecieptF(this.studentsList[i].receipts) != 0 &&
            this.studentsList[i].Category === "МД"
          ) {
            console.log(this.studentsList[i]);
            let temp = [];
            temp.push(this.studentsList[i].Order.ls);
            temp.push(this.studentsList[i].Order.recipientsLastName);
            temp.push(this.studentsList[i].Order.recipientsFirstName);
            temp.push(this.studentsList[i].Order.recipientsSurname);

            if (this.studentsList[i].class.shift === "1 смена") {
              temp.push(
                (
                  (amountRecieptF(this.studentsList[i].receipts) /
                    this.sumOneShift) *
                  this.regionCoef
                ).toFixed(2)
              );
            } else {
              temp.push(
                (
                  (amountRecieptF(this.studentsList[i].receipts) /
                    this.sumTwoShift) *
                  this.regionCoef
                ).toFixed(2)
              );
            }

            arr.push(temp);
          }
        } else {
          if (
            amountRecieptF(this.studentsList[i].receipts) != 0 &&
            this.studentsList[i].Category === "МД" &&
            this.studentsList[i].Order.Sberbank
          ) {
            console.log(this.studentsList[i]);
            let temp = [];
            temp.push(this.studentsList[i].Order.ls);
            temp.push(this.studentsList[i].Order.recipientsLastName);
            temp.push(this.studentsList[i].Order.recipientsFirstName);
            temp.push(this.studentsList[i].Order.recipientsSurname);

            if (this.studentsList[i].class.shift === "1 смена") {
              temp.push(
                (
                  (amountRecieptF(this.studentsList[i].receipts) /
                    this.sumOneShift) *
                  this.regionCoef
                ).toFixed(2)
              );
            } else {
              temp.push(
                (
                  (amountRecieptF(this.studentsList[i].receipts) /
                    this.sumTwoShift) *
                  this.regionCoef
                ).toFixed(2)
              );
            }

            arr.push(temp);
          }
        }
      }

      let csv = arrayToCsv(arr);

      let day = new Date().getDate();
      let month = new Date().getMonth();
      let year = new Date().getFullYear();
      let fileName = "МБ" + day + month + year;
      downloadBlob(csv, fileName, "text/csv;charset=utf-8,");
    },

    createReciept(student) {
      this.selectStudentReciept = student;
      this.dialogReciept = true;
    },
    hiddenRecipts() {
      let cells = document.querySelectorAll(`#columnReciept`);
      console.log(cells);
      cells.forEach((cell) => {
        cell.classList.toggle("hidden");
      });
    },
    changeReciept(student) {
      this.$set(student, "amountRec", this.amountReciept(student.receipts));
      console.log(student);
    },

    async payCompensation() {
      let countPayReciept = 0;
      let countStudents = new Set();
      let arrPayReciept = [];
      for (let i = this.studentsList.length; i--; ) {
        for (let j = this.studentsList[i].receipts.length; j--; ) {
          if (this.studentsList[i].receipts[j].selected) {
            arrPayReciept.push(this.studentsList[i].receipts[j]._id);
            countPayReciept++;
            countStudents.add(this.studentsList[i]._id);
          }
        }
      }

      console.log(arrPayReciept);
      if (
        confirm(
          "Вы собираетесь зафиксировать выдачу компенсации для " +
            countPayReciept +
            " квитанций" +
            "\nДля " +
            countStudents.size +
            " учеников"
        )
      ) {
        let data = {
          recieptList: arrPayReciept,
        };
        await TutorialDataService.PayReciept(data)
          .then((response) => {
            if (response.data.message == "Успешно") {
              console.log("УСПЕШНО ОТПРАВЛЕНО", response.data.message);
              this.init();
            }
          })
          .catch((e) => {
            console.log("1111111111", e);
          });
      }
    },
    async getStudents() {
      await TutorialDataService.findStudentByClassID()
        .then((response) => {
          let a = new Array();
          a = Object.values(response.data);
          console.log(this.studentsList);
          for (let i = 0; i < a.length; i++) {
            if (a[i].Order == null || a[i].Order == undefined) {
              a[i].Order = new Object({
                documents: false,
                Order: { text: "", date: "" },
                reference: { text: "", date: "" },
                cat: "",
                compensation: false,
              });
            }
            if (a[i].Order.compensation) {
              this.studentsList.push(a[i]);
            }
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    async getClass() {
      await TutorialDataService.getAllCLass()
        .then((response) => {
          this.classList = response.data.map(this.mapClass);
        })
        .catch((e) => {
          console.log(e);
        });
    },

    mapClass(data) {
      return {
        classID: data._id,
        className: data.className,
        level: data.level,
        shift: data.shift,
      };
    },
    fullLoad() {
      this.loading = 100;
    },

    async createRecieptServer() {
      let data = {
        classID: this.selectStudentReciept.classID,
        studentID: this.selectStudentReciept._id,
        date: this.tempReciept.date,
        cat: this.selectStudentReciept.Category,
        amount: this.amount,
        identifier: this.tempReciept.identifier,
        period: this.tempReciept.period,
        fileName: "NON",
        pay: false,
      };

      await TutorialDataService.createReciept(data)
        .then((response) => {
          console.log(response.data);
          alert("Квитанция создана", data);
          for (let i = 0; i < this.studentsList.length; i++) {
            if (this.selectStudentReciept._id === this.studentsList[i]._id) {
              this.studentsList[i].receipts.push(data);
              this.studentsList[i].receipts[
                this.studentsList[i].receipts.length - 1
              ].selected = true;

              this.studentsList[i].amountRec = this.amountReciept(
                this.studentsList[i].receipts
              );

              this.tempReciept = {};
              this.amount = 0;
              this.dialogReciept = false;
            }
          }

          this.init();
        })
        .catch((e) => {
          alert(
            "ОШИБКА, Квитанция не сохранена, повторите попытку позднее. Возможно такой ID уже существует."
          );
          console.log(e);
        });
    },
    // testPush() {
    //   let data = {};
    //   data = {
    //     classID: "12",
    //     studentID: "5125",
    //     date: "125",
    //     cat: 125,
    //     amount: 125,
    //     identifier: "1525123",
    //     period: "125123",
    //     fileName: "NON",
    //     pay: false,
    //   };

    //   let rec = this.studentsList[0].receipts;
    //   rec.push(data);
    //   this.studentsList[0].receipts = [];
    //   this.$set(this.studentsList[0], "receipts", rec);

    //   //this.studentsList[0].receipts.push(data);
    //   //this.$forceUpdate();
    //   //this.studentsList[0].receipts.push(data);
    // },
    deleteReciept(data) {
      let text =
        "Вы действительно хотите удалить квитанцию на сумму " +
        data.amount +
        " рублей с ID " +
        data.identifier;
      var isAdmin = confirm(text);

      if (isAdmin) {
        TutorialDataService.deleteReciept(data._id)
          .then((response) => {
            console.log(response);
            alert("Успешно!");
            this.init();
            // window.location.reload();
          })
          .catch((e) => {
            alert("Ошибка");
            console.log(e);
          });
      }
    },
    async getMarksByDateRange() {
      let classID = null;
      let studentID = null;

      await TutorialDataService.findMarksByDateRange({
        classID: classID,
        dateFrom: this.dateFrom,
        dateBefore: this.dateBefore,
        studentID: studentID,
      })
        .then((response) => {
          let temp = Object.values(response.data);
          this.marks = Object.values(temp);
          for (let i = 0; i < this.studentsList.length; i++) {
            this.studentsList[i].amount = 0;
            for (let j = 0; j < this.marks.length; j++) {
              if (
                this.marks[j].studentID === this.studentsList[i]._id &&
                this.marks[j].countEating
              ) {
                console.log("НАШЕЛ!", this.studentsList[i], this.marks[j]);
                this.studentsList[i].amount += this.marks[j].countEating;
              }
            }
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },

    async getRecieptsByRangeDatePOST() {
      let data = {
        classID: null,
        studentID: null,
        dateFrom: this.dateFrom,
        dateBefore: this.dateBefore,
      };

      await TutorialDataService.findRecieptByDateRange(data).then(
        (response) => {
          this.receipts.splice(response.data);
          var a = new Array();
          a = Object.values(response.data);
          for (let i = 0; i < a.length; i++) {
            a[i].datePrint = new Date(a[i].date);
            if (a[i].pay) {
              a[i].selected = false;
            } else {
              a[i].selected = true;
            }

            a[i].datePrint = a[i].datePrint.toLocaleDateString();

            this.$set(this.receipts, i, a[i]);
          }

          for (let i = 0; i < this.studentsList.length; i++) {
            this.studentsList[i].receipts = [];
            this.studentsList[i].amountRec = 0;
            for (let j = this.receipts.length; j--; ) {
              if (this.studentsList[i]._id === this.receipts[j].studentID) {
                this.studentsList[i].receipts.push(this.receipts[j]);
                console.log(this.studentsList[i].receipts);
              }
            }
            this.studentsList[i].amountRec = this.amountReciept(
              this.studentsList[i].receipts
            );
          }
        }
      );
    },

    getClassName() {
      console.log("get class name", this.studentsList[0]);
      for (let j = 0; j < this.studentsList.length; j++) {
        this.studentsList[j].class = {
          className: "this.classList[i].className",
          shift: "this.classList[i].shift",
        };
        for (let i = 0; i < this.classList.length; i++)
          if (this.studentsList[j].classID === this.classList[i].classID) {
            console.log("ЗАШЕЛ");
            this.$set(
              this.studentsList[j].class,
              "className",
              this.classList[i].className
            );
            this.$set(
              this.studentsList[j].class,
              "shift",
              this.classList[i].shift
            );
          }
      }
      this.studentsList
        .sort((a, b) => (a.class.className < b.class.className ? 1 : -1))
        .sort((a, b) => (a.FirstName > b.class.FirstName ? 1 : -1));
    },

    amountReciept: function (recieptList) {
      let amount = 0;
      for (let i = recieptList.length; i--; ) {
        if (recieptList[i].selected) {
          amount += recieptList[i].amount;
        }
      }
      return amount.toFixed(2);
    },
  },
  //   mounted() {
  //     this.init();
  //   },
  watch: {
    amount: function () {
      //this.amount = this.amount.replace(/[^0-9]+/g, "");
      this.amount = this.amount.toString().replace(/[^\d.]/g, ""); // Удаляем символы, кроме "числа" и "."
      this.amount = this.amount.toString().replace(/\.{2,}/g, "."); // Сохраняем только первую. Удаляем лишние.
      this.amount = this.amount
        .toString()
        .replace(".", "$#$")
        .replace(/\./g, "")
        .replace("$#$", ".");
      // this.amount = this.amount
      // .toString()
      // .replace(/^(\-)*(\d+)\.(\d\d).*$/, "$1$2.$3"); // Введите только два десятичных знака
      if (this.amount.indexOf(".") < 0 && this.amount != "") {
        // Вышеупомянутое было отфильтровано. Контроль здесь заключается в том, что если нет десятичной точки, первое место не может быть количеством, аналогичным 01, 02
        this.amount = parseFloat(this.amount);
      }
    },
  },
};
</script>

<style scoped>
#center {
  text-align: center;
}
li {
  list-style-type: none; /* Убираем маркеры */
  padding-left: 0;
  margin-bottom: 3px;
  padding: 0px;
}
label {
  padding: 0px;
}
ul {
  padding-left: 0; /* Сдвигаем список влево */
  margin: 0 3px;
}

ul {
  display: inline-block;
  text-align: left;
  font-size: 12px;
}
TABLE {
  width: 90%; /* Ширина таблицы */
  border-collapse: collapse; /* Убираем двойные линии между ячейками */
}
TD,
TH {
  padding: 2px; /* Поля вокруг содержимого таблицы */
  border: 0.5px solid black; /* Параметры рамки */
}
TH {
  background: #b0e0e6; /* Цвет фона */
  position: sticky;
  top: 0;
  z-index: 220;
}
tr:nth-child(odd) {
  background-color: #ffffff;
  padding: 1px;
}
tr:nth-child(even) {
  background-color: #eeeeee;
  padding: 1px;
}

td {
  background-clip: padding-box;
}

.table {
  cursor: default;
  contain: paint;
  width: 100%;
  font-size: 13px;
}

.table tr {
  position: relative;
}

.table td {
  position: relative;
}

.table td:hover:after {
  pointer-events: none;
  position: absolute;
  z-index: 40;
  top: -5000px;
  left: 0;
  width: 100%;
  height: 10000px;
  content: "";
  /* background-color: rgba(110, 165, 167, 0.151); */
  border: 3px solid #6289ff;
}

li:hover {
  z-index: 50;
  font-size: 105%;
}
#cell:hover {
  z-index: 49;
}

tbody tr:hover {
  background: #deffcd; /* Цвет фона при наведении */
  color: #000000; /* Цвет текста при наведении */
  z-index: 40;
}

.form-check-input {
  margin-top: 7px;
  margin-right: -20px;
  padding: 10px;
}

.hidden {
  display: none;
}
</style>
